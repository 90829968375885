import { Wrap, Select } from "./styles";

function Option({ value, setValue, options, label }) {

  const handleOptionChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <Wrap>
      <Select value={value} onChange={handleOptionChange}>
        <option value="" disabled>
          {label}
        </option>
        {options.map((option, index) => (
          <option key={index} value={option.value || option.id}>{option.label || option.domain}</option>
        ))}
      </Select>
    </Wrap>
  );
}

export default Option;
