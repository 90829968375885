import React from 'react';
import { ArrowLeft, ArrowRight } from './styles';

function Pagination({ page, lastPage, handleHome, searchTerm }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px', marginBottom: '5%'}}>
      {page !== 1 ? <ArrowLeft type='button' onClick={() => handleHome(1, searchTerm)} style={{ marginRight: '5px' }}></ArrowLeft> : <ArrowLeft type='button' disabled style={{ marginRight: '5px' }}><ArrowLeft /></ArrowLeft>}
      {' '}
      {page !== 1 ? <span onClick={() => handleHome(page - 1, searchTerm)} style={{ color: '#FA4616', marginRight: '15px', cursor: 'pointer' }}>{page - 1}</span> : ''}
      {' '}
      <span style={{ color: '#000000', marginRight: '15px', cursor: 'pointer', fontSize: "12px"}}>{page}</span>
      {' '}
      {page + 1 <= lastPage ? <span onClick={() => handleHome(page + 1, searchTerm)} style={{ color: '#FA4616', cursor: 'pointer' }}>{page + 1}</span> : ''}
      {' '}
      {page !== lastPage ? <ArrowRight type='button' onClick={() => handleHome(lastPage, searchTerm)}></ArrowRight> : <ArrowRight type='button' disabled></ArrowRight>}
    </div>
  );
}

export default Pagination;