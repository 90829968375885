import styled from "styled-components";
import { MdKeyboardDoubleArrowLeft } from 'react-icons/md';
import { MdKeyboardDoubleArrowRight } from 'react-icons/md';

export const ArrowLeft = styled(MdKeyboardDoubleArrowLeft)`
  margin: 10px;
  font-size: 15px;
  color: #FA4616;
  cursor: pointer;
  float: right;
`;

export const ArrowRight = styled(MdKeyboardDoubleArrowRight)`
  margin: 10px;
  font-size: 15px;
  color: #FA4616;
  cursor: pointer;
  float: right;
`;