import React from 'react';
import { AiOutlineCloseCircle, AiOutlineDownload } from 'react-icons/ai';

const QRCode = ({ qrCode, onClose }) => {
  const downloadQRCode = () => {
    const link = document.createElement('a');
    link.href = qrCode;
    link.download = 'qrcode.png';
    link.click();
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <button onClick={onClose} style={{ background: 'none', border: 'none', cursor: 'pointer', marginBottom: '10px' }}>
        <AiOutlineCloseCircle size={30} color="#FA4616" />
      </button>
      <img src={qrCode} alt="QR Code" />
      <button onClick={downloadQRCode} style={{ background: 'none', border: 'none', cursor: 'pointer', marginTop: '10px' }}>
        <AiOutlineDownload size={30} color="#FA4616" />
      </button>
    </div>
  );
};

export default QRCode;
