import styled from "styled-components";

export const CloseButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 27%;
  right: 10px;
  font-size: 18px;
`;

export const AlertErrorContainer = styled.div.attrs({ role: "alert" })`
  width: 90%;
  height: 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border: 1px solid red;
  border-radius: 4px;
  box-shadow: 0 0 5px 0 red;
  margin-bottom: 50px;
  position: relative;
  margin-left: 3.5%;
`;

export const AlertSucessContainer = styled.div.attrs({ role: "alert" })`
  width: 90%;
  height: 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border: 1px solid green;
  border-radius: 4px;
  box-shadow: 0 0 5px 0 green;
  margin-bottom: 50px;
  position: relative;
  margin-left: 3.5%;
`;