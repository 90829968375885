import { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import api from "../service/api";
import Container from "../components/Container";
import InputForm from "../components/InputForm";
import ButtonConfirm from "../components/ButtonConfirm";
import Form from "../components/Form";
import Title from "../components/Title";
import Navbar from "../components/Navbar";
import Alert from "../components/Alert";
import DataTable from "../components/DataTable";

function Token() {
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [application, setApplication] = useState('');
  const [DataTokens, setDataTokens] = useState([]);
  const [page, setPage] = useState("");
  const [lastPage, setLastPage] = useState("");
  const auth = useAuth();

  const columns = [
    { header: "ID", key: "ID" },
    { header: "Usuário", key: "user_id" },
    { header: "Token", key: "token" },
    { header: "Aplicação", key: "application" },
    { header: "Status", key: "Status" },
  ];

  const handleRegisterToken = async (e) => {
    e.preventDefault();

    if (!application) {
      // I18N
      setMessage('Por favor, preencha o campo Nome da aplicação.')
      setShowAlert(true);
      return;
    }

    try {
      await api.post("/generateToken", {
        user_id: auth.user.ID,
        application: application,
      }, {
        headers: {
          Authorization: process.env.REACT_APP_TOKKEN,
        },
      });

      setApplication("");

      handleTokens(page);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setMessage(error.response.data.message)
        setShowAlert(true);
        return;
      } else {
        console.log(error);
      }
    }
  };

  const handleTokens = async (page) => {
    try {
      if (page === undefined) {
        page = 1
      }
      setPage(page);
      const response = await api.get("/listTokens?page=" + page, {
        headers: {
          authorization: process.env.REACT_APP_TOKKEN,
        },
      });
      const { tokens } = response.data;
      setDataTokens(tokens);
      setLastPage(response.data.Pagination.lastPage);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleTokens();
  }, []);

  const DeleteToken = async (itemID) => {
    try {
      await api.delete("/deleteToken", {
        data: {
          user_id: auth.user.ID,
          token_id: itemID,
        },
        headers: {
          Authorization: process.env.REACT_APP_TOKKEN,
        },
      });

      handleTokens(page);
    } catch (error) {
      console.log(error);
    }
  };

  const StatusToken = async (itemStatus, itemID) => {
    try {
      await api.post("/editStatusToken", {
          user_id: auth.user.ID,
          status: itemStatus ? false : true,
          token_id: itemID,
      }, {
        headers: {
          Authorization: process.env.REACT_APP_TOKKEN,
        },
      });

      handleTokens(page);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  return (
    <>
      <Navbar />
      <Container>
        <Form>
          {/* I18N */}
          <Title>Gerador de tokens</Title>
          {showAlert && (
            <Alert
              onClose={handleCloseAlert}
              message={message}
            />
          )}
          <InputForm
            // I18N
            dataPlaceholder={"Nome da aplicação:"}
            value={application}
            name={application}
            setName={setApplication}
          />

          <ButtonConfirm onClick={handleRegisterToken} />

          <DataTable
            data={DataTokens}
            columns={columns}
            onDelete={DeleteToken}
            onStatus={StatusToken}
            page={page}
            lastPage={lastPage}
            handleData={handleTokens}
          />
        </Form>
      </Container>
    </>
  );
}

export default Token;