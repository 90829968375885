import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import api from "../service/api";
import Container from "../components/Container";
import InputForm from "../components/InputForm";
import OptionForm from "../components/OptionForm";
import Option from "../components/Option";
import ButtonConfirm from "../components/ButtonConfirm";
import Form from "../components/Form";
import Title from "../components/Title";
import Search from "../components/Search";
import Navbar from "../components/Navbar";
import Alert from "../components/Alert";

function Subdomain() {
  const [url, setUrl] = useState("");
  const [access, setAccess] = useState("");
  const [option, setOption] = useState("");
  const [inputOption, setInputOption] = useState("");
  const [inputRandom, setInputRandom] = useState("");
  const [domains, setDomains] = useState([]);
  const [optionDomain, setOptionDomain] = useState("");
  const [optionStatus, setOptionStatus] = useState("");
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [sucesss, setSucess] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();

  const status = [
    // I18N
    { value: "301", label: "301 Não permite alterações futuras" },
    { value: "302", label: "302 Permite alteração futura" },
  ];

  const handleShortenURL = async (e) => {
    e.preventDefault();

    if (!url) {
      // I18N
      setMessage('Por favor, preencha o campo URL de Destino.')
      setShowAlert(true);
      setSucess(false)
      return;
    }
    if (!access) {
      // I18N
      setMessage('Por favor, preencha o campo Titulo do Registro.')
      setShowAlert(true);
      setSucess(false)
      return;
    }
    if (option === "") {
      // I18N
      setMessage('Por favor, selecione uma opção de subdomínio.')
      setShowAlert(true);
      setSucess(false)
      return;
    }
    if (option === "custom" && inputOption === "") {
      // I18N
      setMessage('Por favor, digite um subdominio customizado.')
      setShowAlert(true);
      setSucess(false)
      return;
    }
    if (option === "random" && inputRandom === "") {
      // I18N
      setMessage('Por favor, digite um valor entre 4 e 9.')
      setShowAlert(true);
      setSucess(false)
      return;
    }
    if (optionDomain === "") {
      // I18N
      setMessage('Por favor, selecione uma opção de domínio.')
      setShowAlert(true);
      setSucess(false)
      return;
    }
    if (optionStatus === "") {
      // I18N
      setMessage('Por favor, selecione uma opção de status.')
      setShowAlert(true);
      setSucess(false)
      return;
    }

    try {
      await api.post("/shortenerURL", {
        user: auth.user.ID,
        url: url,
        access: access,
        option: option,
        inputOption: inputOption,
        inputRandom: inputRandom,
        domain: optionDomain,
        status: optionStatus,
      }, {
        headers: {
          Authorization: process.env.REACT_APP_TOKKEN,
        },
      });

      navigate('/home')
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setMessage(error.response.data.message)
        setShowAlert(true);
        return;
      } else {
        console.log(error);
      }
    }
  };

  const handleDomain = async () => {
    try {
      const response = await api.get("/listDomains", {
        headers: {
          authorization: process.env.REACT_APP_TOKKEN,
        },
      });
      const { domains } = response.data;

      setDomains(domains);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleDomain();
  }, []);

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  return (
    <>
      <Navbar />
      <Container>
        <Form>
          <Search
            // I18N
            placeholder={'Digite o subdomínio de pesquisa...'}
            page={'subdomain'}
          />
          {/* I18N */}
          <Title>Novo Subdomínio</Title>
          {showAlert && (
            <Alert
              onClose={handleCloseAlert}
              message={message}
              validation={sucesss}
            />
          )}
          <InputForm
            // I18N
            dataPlaceholder={"URL de Destino:"}
            value={url}
            name={url}
            setName={setUrl}
          />
          <InputForm
            // I18N
            dataPlaceholder={"Título do Registro:"}
            value={access}
            name={access}
            setName={setAccess}
          />
          <OptionForm
            value={option}
            setValue={setOption}
            inputOption={inputOption}
            inputRandom={inputRandom}
            setInputOption={setInputOption}
            setInputRandom={setInputRandom}
          />
          <Option
            // I18N
            label={'Selecione um Domínio.'}
            value={optionDomain}
            setValue={setOptionDomain}
            options={domains}
          />
          <Option
            // I18N
            label={'Selecione um status.'}
            value={optionStatus}
            setValue={setOptionStatus}
            options={status}
          />
          <ButtonConfirm onClick={handleShortenURL} />
        </Form>

      </Container>
    </>
  );
}

export default Subdomain;