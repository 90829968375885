import React, { useState, useRef } from 'react';
import { TagInput, DragZoneContainer, DropZoneArea, Button, LoadingGearReverse, LoadingGear, Message, MessageSuccess } from "./styles";
import api from "../../service/api";
import { useAuth } from "../../context/AuthContext";
import { FaCheck, FaTimes } from 'react-icons/fa';

const Dragzoneupload = () => {
    const fileInputRef = useRef(null);
    const [uploadStatus, setUploadStatus] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [confirmationVisible, setConfirmationVisible] = useState(false);
    const [previewSource, setPreviewSource] = useState(null);
    const auth = useAuth();
    const user = auth.user.ID;

    const handleButtonClick = () => {
        fileInputRef.current.click();
    }

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];

        if (!file) {
            return;
        }

        setConfirmationVisible(true);
        setPreviewSource(file.name);
    };

    const handleConfirmUpload = async () => {
        setConfirmationVisible(false);
        setUploading(true);

        const formData = new FormData();
        formData.append('files', fileInputRef.current.files[0]);
        formData.append('user_id', user);

        const headers = {
            Authorization: process.env.REACT_APP_TOKKEN,
        };

        try {
            const response = await api.post('/uploadCSV', formData, { headers });
            const responseData = response.data;

            setUploadStatus(responseData);

            setTimeout(() => {
                window.location.reload();
            }, 1500);
        } catch (error) {
            console.error('Erro ao fazer upload:', error);
            setUploadStatus('Erro ao fazer upload para API.');
        } finally {
            setUploading(false);
        }
    };

    return (
        <DragZoneContainer>
            <DropZoneArea>
                {uploading ? (
                    <div>
                        <LoadingGear />
                        <LoadingGearReverse />
                        <Message>Processo de upload iniciado. Por favor, aguarde...</Message>
                    </div>
                ) : (
                    confirmationVisible ? (
                        <div>
                            <Message>Confirmar envio do arquivo "{previewSource}" ?</Message>
                            <div style={{ display: "flex", marginLeft: "25%" }}>
                                <Button onClick={() => setConfirmationVisible(false)}><FaTimes /></Button>
                                <Button onClick={handleConfirmUpload}><FaCheck /></Button>
                            </div>
                        </div>
                    ) : (
                        (uploadStatus && uploadStatus.message) ? (
                            <MessageSuccess>{uploadStatus.message}</MessageSuccess>
                        ) : (
                            <Message type="button" onClick={handleButtonClick}>
                                Procurar arquivo CSV para upload...
                            </Message>
                        )
                    )
                )}
                <TagInput
                    ref={fileInputRef}
                    className="file-input"
                    type="file"
                    name="files"
                    accept=".csv"
                    onChange={handleFileUpload}
                />
                <i className="fas fa-cloud-upload-alt"></i>
            </DropZoneArea>
        </DragZoneContainer>
    );
}

export default Dragzoneupload;
