import React from 'react';
import { Nav, StyledNavLink, HomeIcon } from './styles';
import { useAuth } from '../../context/AuthContext';
import Logo from '../../images/logo.png';


function Navbar() {
  const auth = useAuth();

  const handleLogout = () => {
    auth.logout();
  };

  const admin = auth.user ? auth.user.ID : 0

  return (
    <Nav>
      <div style={{ display: "flex", alignItems: "center" }}>
        {/* <StyledNavLink to="/home"><HomeIcon style={{ fontSize: "1.4rem" }} /></StyledNavLink> */}
        <StyledNavLink to="/home"><img src={Logo} style={{ width: "9rem", height: "1.4rem" }} /></StyledNavLink>
        {/* <StyledNavLink to="/home" style={{ fontSize: "12px" }}>Curtinho</StyledNavLink> */}
      </div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flex: 1, fontSize: "12px" }}>
        {/* I18N */}
        <StyledNavLink to="/subdomain">Novo subdomínio</StyledNavLink>
        <StyledNavLink to="/upload">Uploads</StyledNavLink>
        {admin === 1 && <StyledNavLink to="/user">Usuários</StyledNavLink>}
        {admin === 1 && <StyledNavLink to="/token">Tokens</StyledNavLink>}
      </div>
      <div style={{ marginLeft: "auto" }}>
        {/* I18N */}
        <StyledNavLink onClick={handleLogout} to="/login" style={{ fontSize: "12px" }}>Sair</StyledNavLink>
      </div>
    </Nav>
  );
}

export default Navbar;