import styled from "styled-components";

const Form = styled.form`
    position: absolute;
    top: 10%;
    width: 1300px;
    overflow: hidden;

    @media (max-width: 1400px) {
        width: 1000px;
        overflow: hidden;
    }
`;

export default Form;