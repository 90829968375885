import React, { useState } from 'react';
import { Wrap, Table, Thead, Tr, Th, Td, DeleteIcon, EditIcon, CheckIcon, QRIcon, StatusOn, StatusOff, CancelIcon, CopyIcon } from "./styles";
import Pagination from '../Pagination';
import QRCode from '../QRCode';
import api from "../../service/api";

export default function DataTable({
  data,
  columns,
  onDelete,
  onEdit,
  onQr,
  columnEdit,
  onStatus,
  handleData,
  page,
  lastPage,
  searchTerm,
  onCopy
}) {
  const [editableRows, setEditableRows] = useState([]);
  const [editedValues, setEditedValues] = useState({});
  const [showQr, setShowQr] = useState(false);
  const [qrCode, setQRcode] = useState("");

  const handleEditClick = (item) => {
    if (!editableRows.includes(item.ID)) {
      setEditableRows([item.ID]);
    }
  };

  const handleCancelEdit = (itemId) => {
    setEditableRows((prev) => prev.filter((id) => id !== itemId));

    setEditedValues((prev) => {
      const editedValuesCopy = { ...prev };
      delete editedValuesCopy[itemId];
      return editedValuesCopy;
    });
  };

  const handleQrCodeClose = () => {
    setShowQr(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${hours}:${minutes}:${seconds} - ${day}/${month}/${year}`;
  };

  const GenerateQR = async (itemSubdomain) => {
    try {
      const response = await api.post("/generateQrcode", {
        subdomain: itemSubdomain,
      }, {
        headers: {
          Authorization: process.env.REACT_APP_TOKKEN,
        },
      });

      setQRcode(response.data.qrCodeData);
      setShowQr(true)
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {showQr && (
        <QRCode qrCode={qrCode} onClose={handleQrCodeClose} />
      )}

      <Wrap>
        <Table>
          <Thead>
            <Tr>
              {columns.map((column, index) => (
                <Th key={index}>{column.header}</Th>
              ))}
              <Th></Th>
            </Tr>
          </Thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                {columns.map((column, columnIndex) => (
                  <Td key={columnIndex}>
                    {column.key === columnEdit && editableRows.includes(item.ID) ? (
                      <>
                        <input
                          type={columnEdit === 'password' ? 'password' : 'text'}
                          value={editedValues[item.ID] || ""}
                          onChange={(e) =>
                            setEditedValues((prev) => ({
                              ...prev,
                              [item.ID]: e.target.value,
                            }))
                          }
                          style={{ width: '80%' }}
                        />
                        <CancelIcon onClick={() => handleCancelEdit(item.ID)} />
                        <CheckIcon onClick={() => onEdit(item.ID, editedValues[item.ID])} />
                      </>
                    ) : (
                      column.key === 'password'
                        ? '********'
                        : column.key === 'updatedAt'
                          ? formatDate(item[column.key])
                          : column.key === 'shortenerURL'
                            ? (
                              <>
                                {item[column.key]}
                                <CopyIcon onClick={() => onCopy(item.shortenerURL)} />
                              </>
                            )
                            : item[column.key]
                    )}
                  </Td>
                ))}

                <Td>
                  {onDelete && <DeleteIcon onClick={() => onDelete(item.ID)} />}
                  {onEdit ? (
                    item.status !== 301 ? (
                      <EditIcon onClick={() => handleEditClick(item)} />
                    ) : (
                      <EditIcon style={{ opacity: 0.5, cursor: 'not-allowed' }} />
                    )
                  ) : null}
                  {onStatus && (item.isActive ? <StatusOn onClick={() => onStatus(item.isActive, item.ID)} /> : <StatusOff onClick={() => onStatus(item.isActive, item.ID)} />)}
                  {onQr && <QRIcon onClick={() => GenerateQR(item.subdomain)} />}
                </Td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Wrap>
      <Pagination page={page} lastPage={lastPage} handleHome={handleData} searchTerm={searchTerm} />
    </>
  );
}