import React from "react";
import { Wrap, Select, Input, FocusInput } from "./styles";

function OptionForm({ value, setValue, inputOption, setInputOption, inputRandom, setInputRandom }) {
  const handleOptionChange = (e) => {
    setValue(e.target.value);
    setInputOption("");
    setInputRandom("");
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;

    if (value === "custom") {
      setInputOption(newValue);
    } else if (value === "random") {
      if (newValue === "" || (newValue >= 4 && newValue <= 9)) {
        setInputRandom(newValue);
      }
    }
  };

  return (
    <Wrap>
      <Select value={value} onChange={handleOptionChange} required>
        <option value="" disabled>
          Selecione uma opção de subdomínio.
        </option>
        <option value="random">Encurtador Aleatório</option>
        <option value="custom">Encurtador Customizado</option>
      </Select>
      {value === "custom" && (
        <div>
          <Input
            // I18N
            placeholder="Digite um valor"
            type="text"
            value={inputOption}
            onChange={(e) => setInputOption(e.target.value)}
          />
          <FocusInput />
        </div>
      )}
      {value === "random" && (
        <div>
          <Input
            // I18N
            placeholder="Digite um valor"
            type="number"
            value={inputRandom}
            onChange={handleInputChange}
            min="4"
            max="9"
          />
          <FocusInput />
        </div>
      )}
    </Wrap>
  );
}

export default OptionForm;