import React, { useState, useEffect } from "react";
import api from "../service/api";
import Container from "../components/Container";
import Form from "../components/Form";
import DataTable from "../components/DataTable";
import Navbar from "../components/Navbar";
import Search from "../components/SearchHome";
import Alert from "../components/Alert";

const columnsURL = [
  { header: "Usuário", key: "user" },
  { header: "Titulo", key: "title" },
  { header: "Link", key: "link" },
  { header: "Domínio", key: "domain" },
  { header: "Subdomínio", key: "subdomain" },
  { header: "Status", key: "status" },
  {
    header: "Link-curto", key: `shortenerURL`,
  },
];

const columnsAccess = [
  { header: "Subdomínio", key: "subdomain" },
  { header: "Acessos", key: "Access" },
  { header: "Último Acesso", key: "updatedAt" },
];

function Home() {
  const [DataUrl, setUrlDataUrl] = useState([]);
  const [DataAccess, setUrlDataAccess] = useState([]);
  const [pageUrl, setPageUrl] = useState("");
  const [lastPageUrl, setLastPageUrl] = useState("");
  const [pageAccess, setPageAccess] = useState("");
  const [lastPageAccess, setLastPageAccess] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");

  const handleUrl = async (page, inputValue) => {
    try {
      if (page === undefined || page === null) {
        page = 1;
      }

      const requestData = {
        page: page,
        inputValue: inputValue,
      };

      const response = await api.get("/home", {
        headers: {
          authorization: process.env.REACT_APP_TOKKEN,
        },
        params: requestData,
      });

      const { url } = response.data;

      setPageUrl(page);
      setUrlDataUrl(url);
      setLastPageUrl(response.data.Pagination.lastPage);
      setSearchTerm(inputValue)
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleUrl();
  }, []);

  const handleAccess = async (page) => {
    try {
      if (page === undefined) {
        page = 1;
      }
      setPageAccess(page);
      const response = await api.get("/home?page=" + page, {
        headers: {
          authorization: process.env.REACT_APP_TOKKEN,
        },
      });
      const { access } = response.data;
      setUrlDataAccess(access);

      setLastPageAccess(response.data.Pagination.lastPage);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleAccess();
  }, []);

  const DeleteURL = async (itemID) => {
    try {
      await api.delete("/deleteURL", {
        data: {
          itemID: itemID,
        },
        headers: {
          Authorization: process.env.REACT_APP_TOKKEN,
        },
      });

      handleUrl(pageUrl, searchTerm);
    } catch (error) {
      console.log(error);
    }
  };

  const EditUrl = async (itemID, newValue) => {
    try {
      await api.put("/editURL", {
        id: itemID,
        newValue: newValue,
      }, {
        headers: {
          Authorization: process.env.REACT_APP_TOKKEN,
        },
      });

      handleUrl(pageUrl, searchTerm);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCopy = (textToCopy) => {
    const tempInput = document.createElement('input');
    tempInput.value = textToCopy;
  
    document.body.appendChild(tempInput);
  
    tempInput.select();
  
    document.execCommand('copy');
  
    document.body.removeChild(tempInput);
  
    // alert('Valor copiado para a área de transferência');
    setShowAlert(true)
    setMessage('Valor copiado para a área de transferência')
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  return (
    <>
      <Navbar />
      <Container>
        <Form>
          <Search
            // I18N
            placeholder={'Digite o título de pesquisa...'}
            onInputChange={handleUrl}
          />

          {showAlert && (
            <div style={{ marginTop: "40px" }}>
              <Alert
                onClose={handleCloseAlert}
                message={message}
                validation={true}
              />
            </div>
          )}

          <DataTable
            data={DataUrl}
            columns={columnsURL}
            onDelete={DeleteURL}
            onEdit={EditUrl}
            columnEdit={'link'}
            onQr={true}
            onCopy={handleCopy}
            page={pageUrl}
            lastPage={lastPageUrl}
            handleData={handleUrl}
            searchTerm={searchTerm}
          />

          <DataTable
            data={DataAccess}
            columns={columnsAccess}
            page={pageAccess}
            lastPage={lastPageAccess}
            handleData={handleAccess}
            searchTerm={searchTerm}
          />
        </Form>
      </Container>
    </>
  );
}

export default Home;