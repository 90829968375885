import styled from "styled-components";

export const Wrap = styled.div`
  width: 100%;
  position: relative;
  border-bottom: 2px solid #242424;
  margin-bottom: 37px;
`;

export const FocusInput = styled.span`
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  color: #242424;

  &::before {
    content: "";
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    transition: all 0.4s;
    background-color: #FA4616;
  }

  &::after {
    font-size: 15px;
    color: #242424;
    line-height: 1.2;
    content: attr(data-placeholder);
    padding-left: 5px;
    transition: all 0.4s;
  }
`;

export const Select = styled.select`
  font-size: 15px;
  color: #242424;
  line-height: 1.2;
  border: none;
  display: block;
  width: 100%;
  height: 45px;
  background-color: transparent;
  padding: 0 5px;
  caret-color: #1fe070;

  option {
    color: red;
  }

  &:focus {
    outline: 0;
  }

  &:focus + ${FocusInput}::before {
    width: 100%;
  }

  &:focus + ${FocusInput}::after {
    top: -15px;
    font-size: 12px;
    color: #242424;
  }

  &.has-val + ${FocusInput}::after {
    top: -15px;
    font-size: 12px;
    color: #242424;
  }
`;

export const Input = styled.input`
  font-size: 15px;
  color: #242424;
  line-height: 1.2;
  border: none;
  display: block;
  background-clip: text;
  width: 99%;
  height: 45px;
  background-color: transparent;
  padding: 0 5px;
  caret-color: #FA4616;

  &:focus {
    outline: 0;
  }

  &:focus + ${FocusInput}::before {
    width: 100%;
  }

  &:focus + ${FocusInput}::after {
    top: -15px;
    font-size: 12px;
    color: #242424;
  }

  &.has-val + ${FocusInput}::after{
    top: -15px;
    font-size: 12px;
    color: #242424;
  }
`;