import styled from "styled-components";

export const Wrap = styled.div`
  width: 100%;
  position: relative;
  border-bottom: 2px solid #242424;
  margin-bottom: 37px;
`;

export const Select = styled.select`
  font-size: 15px;
  color: #242424;
  line-height: 1.2;
  border: none;
  display: block;
  width: 100%;
  height: 45px;
  background-color: transparent;
  padding: 0 5px;

  &:focus {
    outline: 0;
  }
`;