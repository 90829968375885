import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { SearchContainer, SearchInput, SearchButton } from "./styles";

const SearchHome = ({ placeholder, onInputChange }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchTerm(inputValue);
    onInputChange(null, inputValue);
  };

  return (
    <div>
      <SearchContainer>
        <SearchInput
          // I18N
          placeholder={placeholder}
          type="text"
          value={searchTerm}
          name={searchTerm}
          onChange={handleSearchInputChange}
        />
        <SearchButton>
          <FaSearch size={15} />
        </SearchButton>

      </SearchContainer>
    </div>
  );
};

export default SearchHome;