import Container from "../components/Container";
import Form from "../components/Form";
import Title from "../components/Title";
import Navbar from "../components/Navbar";
import Dragzoneupload from "../components/Dragzoneupload";
import { FaDownload } from 'react-icons/fa';
import { CSVLink } from "react-csv";

function UploadCSV() {
  const headers = [
    { label: "url", key: "url" },
    { label: "access", key: "access" },
    { label: "option", key: "option" },
    { label: "domain", key: "domain" },
    { label: "status", key: "status" }
  ];

  const data = [
    { url: "https://www.example1.com.br", access: "Title1", option: "random", domain: "ldm.site", status: 301 },
    { url: "https://www.example2.com.br", access: "Title2", option: "random", domain: "ldm.site", status: 302 },
    { url: "https://www.example3.com.br", access: "Title3", option: "random", domain: "ldm.site", status: 301 }
  ];

  return (
    <>
      <Navbar />
      <Container>
        <Form>
          {/* I18N */}
          <Title>Carregar CSV</Title>
          <Dragzoneupload />
        </Form>
        <div style={{ margin: "40% 0 0 0"}}>
          <p style={{ textAlign: "center", color: "#FA4616" }}>
            {/* I18N */}
            Faça o download do modelo CSV para upload...
          </p>

          <CSVLink
            data={data}
            headers={headers}
            style={{ display: "block", margin: "10% 0 0 50%", cursor: "pointer", color: "#FA4616" }}
          >
            <FaDownload />
          </CSVLink>
        </div>
      </Container>
    </>
  );
}

export default UploadCSV;