import styled from "styled-components";
import { BsFillTrashFill } from 'react-icons/bs';
import { AiFillEdit } from 'react-icons/ai';
import { FaCheckCircle, FaQrcode, FaToggleOn, FaToggleOff, FaTimes, FaCopy } from 'react-icons/fa';

export const Wrap = styled.div`
  margin-top: 5%;
  margin-bottom: 2%;
  width: 100%;
  max-height: 1000px;
  overflow-y: hidden; 
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const Thead = styled.thead`
  width: 100%;
  color: white;
  position: sticky;
  top: 0;
  z-index: 1; 
`;

export const Tr = styled.tr`
  background-color: #FA4616;
`;

export const Th = styled.th`
  text-align: left;
  padding: 8px;
  background-color: transparent;
  font-size: 12px;
`;

export const Td = styled.td`
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd;
  text-align: left;
  font-size: 12px;
`;

export const DeleteIcon = styled(BsFillTrashFill)`
  margin: 5px;
  color: #FA4616;
  cursor: pointer;
  float: right;
`;

export const EditIcon = styled(AiFillEdit)`
  margin: 5px;
  color: #FA4616;
  cursor: pointer;
  float: right;
`;

export const CheckIcon = styled(FaCheckCircle)`
  margin: 5px;
  color: #FA4616;
  cursor: pointer;
  float: right;
`;

export const QRIcon = styled(FaQrcode)`
  margin: 5px;
  color: #FA4616;
  cursor: pointer;
  float: right;
`;

export const StatusOn = styled(FaToggleOn)`
  margin: 5px;
  color: #FA4616;
  cursor: pointer;
  float: right;
`;

export const StatusOff = styled(FaToggleOff)`
  margin: 5px;
  color: #FA4616;
  cursor: pointer;
  float: right;
`;

export const CancelIcon = styled(FaTimes)`
  margin: 5px;
  color: #FA4616;
  cursor: pointer;
  float: right;
`;

export const CopyIcon = styled(FaCopy)`
  margin: 5px;
  color: #FA4616;
  cursor: pointer;
  float: right;
`;