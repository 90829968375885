import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { BiHomeAlt2 } from 'react-icons/bi';

export const Nav = styled.nav`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 30px;
  background-color: #FA4616;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 0 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
`;

export const StyledNavLink = styled(Link)`
  color: white;
  margin: 0 20px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const HomeIcon = styled(BiHomeAlt2)`
  color: white;
`;