import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import api from "../../service/api";
import { SearchContainer, SearchInput, SearchButton } from "./styles";
import Alert from "../Alert";

const SearchComponent = ({ placeholder, page }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const Search = async (e) => {
    e.preventDefault();

    if (!searchTerm) {
      setShowAlert(true);
      return;
    }

    try {
      const response = await api.get(`/search/${searchTerm}`, {
        headers: {
          Authorization: process.env.REACT_APP_TOKKEN,
        },
        params: {
          page: page,
        },
      });
      setSearchResults(response.data.result);
    } catch (error) {
      console.log(error);
      // I18N
      alert("Ocorreu um erro ao buscar os resultados.");
    }
  };

  return (
    <div style={{ padding: "0", margin: "0 0 100px 0" }}>

      {showAlert && (
        <Alert onClose={handleCloseAlert}>
          {/* I18N */}
          <p>Por favor, preencha o campo de busca.</p>
        </Alert>
      )}

      <SearchContainer>
        <SearchInput
          // I18N
          placeholder={placeholder}
          type="text"
          value={searchTerm}
          name={searchTerm}
          onChange={handleSearchInputChange}
        />
        <SearchButton onClick={(e) => Search(e, page)}>
          <FaSearch size={15} />
        </SearchButton>

      </SearchContainer>

      {searchResults !== null ? (
        // I18N
        searchResults ? (
          <div>{page === 'domain' ? 'Domínio' : 'Subdomínio'} encontrado: {searchResults}</div>
        ) : (
          // I18N
          <div>Nenhum resultado encontrado.</div>
        )
      ) : null}
    </div>
  );
};

export default SearchComponent;