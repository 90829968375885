import React from "react";
import { FaTimes } from "react-icons/fa";
import { AlertErrorContainer, AlertSucessContainer, CloseButton } from "./styles";

const Alert = ({ onClose, message, validation, reload }) => {
  const handleClose = () => {
    if (reload) {
      window.location.reload();
    } else {
      onClose();
    }
  };

  if (validation) {
    return (
      <AlertSucessContainer>
        <span style={{ fontSize: "12px" }}>
          {message}
        </span>
        <CloseButton onClick={handleClose}>
          <FaTimes />
        </CloseButton>
      </AlertSucessContainer>
    );
  } else {
    return (
      <AlertErrorContainer>
        <span style={{ fontSize: "12px" }}>
          {message}
        </span>
        <CloseButton onClick={handleClose}>
          <FaTimes />
        </CloseButton>
      </AlertErrorContainer>
    );
  }
};

export default Alert;