import styled from "styled-components";

const Title = styled.span`
    display: block;
    font-size: 25px;
    background-clip: text;
    -webkit-background-clip: text;
    background-color: #8A8A8A;
    color: transparent;

    line-height: 1.2;
    text-align: center;
    margin-bottom: 5%;
    margin-top: 5%;
    text-transform: uppercase;
`;

export default function Button({ children }) {
  return (
    <Title>
      {children}
    </Title>
  );
};