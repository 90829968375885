import styled from "styled-components";

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: transparent; 
  width: 100%;
  max-width: 400px;
  padding: 10px; 
  border: 1px solid #000000; 
  border-radius: 8px;
`;

export const SearchInput = styled.input`
  flex: 1;
  border: none;
  outline: none;
  background-color: transparent;
`;

export const SearchButton = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  color: #000000;
`;