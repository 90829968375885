import { WrapInput, Input, FocusInput } from './styles';

const InputForm = ({ name, setName, dataPlaceholder, value, type }) => {
  return (
    <WrapInput>
      <Input
        className={name !== "" ? "has-val" : ""}
        type={type}
        name={name}
        value={value}
        onChange={(e) => setName(e.target.value)}
        required={true}
      />
      <FocusInput
        data-placeholder={dataPlaceholder}
      />
    </WrapInput>
  );
};

export default InputForm;