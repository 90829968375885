import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 15px;
    height: 100vh;
    overflow: hidden; 
`

export default Container;