import { useState, useEffect } from "react";
import api from "../service/api";
import Container from "../components/Container";
import InputForm from "../components/InputForm";
import ButtonConfirm from "../components/ButtonConfirm";
import Form from "../components/Form";
import Title from "../components/Title";
import Navbar from "../components/Navbar";
import Alert from "../components/Alert";
import DataTable from "../components/DataTable";

function Users() {
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [DataUsers, setDataUsers] = useState([]);
  const [page, setPage] = useState("");
  const [lastPage, setLastPage] = useState("");

  const columns = [
    { header: "ID", key: "ID" },
    { header: "Nome", key: "name" },
    { header: "Email", key: "email" },
    { header: "Senha", key: "password" },
  ];

  const handleRegisterUser = async (e) => {
    e.preventDefault();

    if (!name) {
      // I18N
      setMessage('Por favor, preencha o campo Nome do usuário.')
      setShowAlert(true);
      return;
    }
    if (!email) {
      // I18N
      setMessage('Por favor, preencha o campo Email.')
      setShowAlert(true);
      return;
    }
    if (!password) {
      // I18N
      setMessage('Por favor, preencha o campo Password.')
      setShowAlert(true);
      return;
    }

    try {
      await api.post("/registerUser", {
        name: name,
        email: email,
        password: password,
      }, {
        headers: {
          Authorization: process.env.REACT_APP_TOKKEN,
        },
      });

      setName("");
      setEmail("");
      setPassword("");
      handleUsers(page);

    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setMessage(error.response.data.message)
        setShowAlert(true);
        return;
      } else {
        console.log(error);
      }
    }
  };

  const handleUsers = async (page) => {
    try {
      if(page === undefined){
        page = 1
      }
      setPage(page)
      const response = await api.get("/listUsers?page=" + page, {
        headers: {
          authorization: process.env.REACT_APP_TOKKEN,
        },
      });
      const { users } = response.data;
      setDataUsers(users);
      setLastPage(response.data.Pagination.lastPage);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleUsers();
  }, []);

  const DeleteUser = async (itemID) => {
    try {
      await api.delete("/deleteUser", {
        data: {
          ID: itemID,
        },
        headers: {
          Authorization: process.env.REACT_APP_TOKKEN,
        },
      });

      handleUsers(page);
    } catch (error) {
      console.log(error);
    }
  };

  const onEdit = async (itemID, newValue) => {
    try {
      await api.put("/editUser", {
        ID: itemID,
        newPassword: newValue,
      }, {
        headers: {
          Authorization: process.env.REACT_APP_TOKKEN,
        },
      });

      handleUsers(page);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  return (
    <>
      <Navbar />
      <Container>
        <Form>
          {/* I18N */}
          <Title>Registro de usuários</Title>
          {showAlert && (
            <Alert
              onClose={handleCloseAlert}
              message={message}
            />
          )}
          <InputForm
            // I18N
            dataPlaceholder={"Nome do usuário:"}
            value={name}
            name={name}
            setName={setName}
          />

          <InputForm
            dataPlaceholder={"Email:"}
            value={email}
            name={email}
            setName={setEmail}
          />

          <InputForm
            // I18N
            dataPlaceholder={"Senha:"}
            value={password}
            name={password}
            setName={setPassword}
            type={'password'}
          />

          <ButtonConfirm onClick={handleRegisterUser} />

          <DataTable
            columns={columns}
            data={DataUsers}
            onDelete={DeleteUser}
            onEdit={onEdit}
            columnEdit={'password'}
            page={page}
            lastPage={lastPage}
            handleData={handleUsers}
          />
        </Form>
      </Container>
    </>
  );
}

export default Users;