import { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../service/api";
import { useAuth } from "../context/AuthContext";
import Container from "../components/Container";
import InputForm from "../components/InputForm";
import ButtonConfirm from "../components/ButtonConfirm";
import Form from "../components/Form";
import Title from "../components/Title";
import Alert from "../components/Alert";
import Card from "../components/Card";

function Login() {
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const auth = useAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!email) {
      // I18N
      setMessage('Por favor, preencha o campo Email.')
      setShowAlert(true);
      return;
    }
    if (!password) {
      // I18N
      setMessage('Por favor, preencha o campo Password.')
      setShowAlert(true);
      return;
    }

    try {
      const response = await api.post("/login", {
        email: email,
        password: password,
      }, {
        headers: {
          Authorization: process.env.REACT_APP_TOKKEN,
        },
      });

      setEmail("");
      setPassword("");

      if (response.data.message) {
        auth.login();
        auth.setUser(response.data.user);
        localStorage.setItem('isAuthenticated', 'true');
        navigate('/home');
      }

    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setMessage(error.response.data.message)
        setShowAlert(true);
        return;
      } else {
        console.log(error);
      }
    }
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  return (
    <>
      <Container>
        <Form>

          <div style={{ width: "480px", margin: "15px 0 0 250px"}}>
            {showAlert && (
              <Alert
                onClose={handleCloseAlert}
                message={message}
                validation={false}
              />
            )}
          </div>
          <Card>
            {/* I18N */}
            <Title>Login</Title>
            < div style={{ marginTop: "100px" }}>
              <InputForm
                dataPlaceholder={"Email:"}
                value={email}
                name={email}
                setName={setEmail}
              />

              <InputForm
                // I18N
                dataPlaceholder={"Senha:"}
                value={password}
                name={password}
                setName={setPassword}
                type={'password'}
              />
            </div>
            <ButtonConfirm onClick={handleLogin} style={{ marginTop: "175px" }} />
          </Card>
        </Form>
      </Container>
    </>
  );
}

export default Login;