import styled, { keyframes } from 'styled-components';
import { BsGear } from 'react-icons/bs';

export const DragZoneContainer = styled.div`
    width: 80%;
    padding: 10px;
    border: 3px dashed #FA4616;
    border-radius: 8px;
    min-height: 150px;
    text-align: center;
    color: #FA4616;
    display: flex;
    flex-flow: column;
    flex-direction: column;
    background-color: transparent;
    cursor: pointer;
    margin: 0 0 0 10%;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin-top: 150px;
`;

export const TableContainer = styled.div`
    width: 100%;
    padding: 10px;
    min-height: 150px;
`;

export const DropZoneArea = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
`;

export const TagInput = styled.input`
  flex: 1;
  display:none;
  border: none;
  width:100%;
  
  &:focus {
      outline: transparent;
  }
`;

export const Button = styled.button`
    font-size: 14px;
    border: none;
    border-radius: 5px;
    color: #fff;
    background-color: #FA4616;
    padding: 8px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 150px;
    height: 36px;
    margin-top: 10px;
    margin-right: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        top: -5px;
        left: -5px;
        right: -5px;
        bottom: -5px;
        border: 2px solid transparent;
        border-radius: 8px;
        transition: border-color 0.2s ease-in-out;
    }

    &:hover:before {
        border-color: #FA4616;
    }
`;

export const Form = styled.form`
width: 100%;
`
const spin = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const spinReverse = keyframes`
    from {
        transform: rotate(-0deg);
    }
    to {
        transform: rotate(-360deg);
    }
`;

export const LoadingGear = styled(BsGear)`
    font-size: 2.5rem; 
    animation: ${spin} 3s linear infinite; 
    margin-left: 5px;
`;

export const LoadingGearReverse = styled(BsGear)`
  font-size: 1.8rem;
  animation: ${spinReverse} 3s linear infinite;
  margin-bottom: 15px;
`;

export const Message = styled.p`
    margin-top: 5%; 
`;

export const MessageSuccess = styled.p`
    color: #FA4616;
`;