import styled from "styled-components";

const Button = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;

    button {
      font-size: 12px;
      border: none;
      border-radius: 8px;
      color: #fff;
      line-height: 1.2;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 30px;
      background-color: #FA4616;
    }

    button:hover {
      cursor: pointer;
    }
`;

export default Button;