import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

import Home from '../pages/home';
import Login from '../pages/login';
import Users from '../pages/users';
// import Domain from '../pages/domain';
import SubDomain from '../pages/subdomain';
import UploadCSV from '../pages/uploadCSV';
// import Product from '../pages/product';
import Token from '../pages/token';

function PrivateRoute({ element, ...rest }) {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? element : <Navigate to="/login" />;
}

function AppRoutes() {
  const auth = useAuth();
  const admin = auth.user ? auth.user.ID : 0

  return (
    <BrowserRouter>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />

        {/* Private Routes Admin*/}
        {admin === 1 && <Route path="/user" element={<PrivateRoute element={<Users />} />} />}
        {admin === 1 && <Route path="/token" element={<PrivateRoute element={<Token />} />} />}

        {/* Private Routes */}
        <Route path="/home" element={<PrivateRoute element={<Home />} />} />
        {/* <Route path="/domain" element={<PrivateRoute element={<Domain />} />} /> */}
        <Route path="/subdomain" element={<PrivateRoute element={<SubDomain />} />} />
        <Route path="/upload" element={<PrivateRoute element={<UploadCSV />} />} />
        {/* <Route path="/product" element={<PrivateRoute element={<Product />} />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;